import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from 'gatsby-background-image'
import { graphql, Link, useStaticQuery } from "gatsby"
import { getImage, StaticImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

const IndexPage = () => {
  const { placeholderImage } = useStaticQuery(query);
  const image = getImage(placeholderImage)
  const bgImage = convertToBgImage(image)

  return (
    <>
      <Layout>
        <SEO title="Home" />

        <BackgroundImage
          Tag="section"
          {...bgImage}
          preserveStackingContext
          className="-mt-32 h-screen object-cover"
        >
          <div className="container h-full w-full mx-auto grid items-end">
            <div className="w-full my-5 mx-auto">
              <div className="max-w-lg mx-auto opacity-90">
                <div className="p-4 bg-gradient-to-r from-gray-500 to-[#0297EC] text-white rounded-3xl">
                  <div className="grid justify-items-center">
                    <h1 className="font-bold text-4xl">Get Interest Free Loans</h1>
                    <p>Your Halaal Financial Partner</p>
                  </div>
                </div>
              </div>

              <div className="p-4 grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="p-4 bg-[#0297EC] opacity-80 shadow rounded-md text-white text-sm">
                  <div className="grid justify-items-center">
                    <p className="pb-1 font-bold text-lg border-b-2">Save with us</p>
                    <p className="mt-2">Find out more about our range of different savings accounts. Easy to open with shortest time possible.</p>
                    <p className="mt-5">
                      <Link to="/savings" className="py-2 px-4 bg-yellow-500 rounded text-black">
                        View our savings products
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="p-4 bg-[#0297EC] opacity-80 shadow rounded-md text-white text-sm">
                  <div className="grid justify-items-center">
                    <p className="pb-1 font-bold text-lg border-b-2">Open an account</p>
                    <p className="mt-2">Have a look at our different accounts that suitable for you and your loved ones.</p>
                    <p className="mt-5">
                      <Link to="/membership" className="py-2 px-4 bg-yellow-500 rounded text-black">
                        View accounts
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="p-4 bg-[#0297EC] opacity-80 shadow rounded-md text-white text-sm">
                  <div className="grid justify-items-center">
                    <p className="pb-1 font-bold text-lg border-b-2">Loans</p>
                    <p className="mt-2">Check out our variety of loans that fit your need as well as how to register.</p>
                    <p className="mt-5">
                      <Link to="/loans" className="py-2 px-4 bg-yellow-500 rounded text-black">
                        View loans
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>            
          </div>
        </BackgroundImage>

        <section className="py-10 px-4">
          <div className="container max-w-5xl mx-auto">
            <h2 className="font-bold text-3xl text-center">Savings Account</h2>
            <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 items-center justify-items-center">
              <div className="p-2 rounded-md shadow">
                <div className="h-64 w-full rounded-md overflow-hidden">
                  <StaticImage
                    className="object-cover h-full"
                    src="../images/abed-ismail.png"
                    alt="Abed Ismail car image"
                    quality={95}                    
                  />
                </div>
                <div className="my-4 text-center">
                  <h3 className="font-semibold text-[#2699FB]">
                    Business Financing
                  </h3>
                  <p className="mt-2 text-xs">
                    This is done by partnership basis through sharing of profit/loss
                  </p>
                </div>
              </div>
              <div className="p-2 rounded-md shadow">
                <div className="h-64 w-full rounded-md overflow-hidden">
                  <StaticImage
                    className="object-cover h-full"
                    src="../images/wild-life.png"
                    alt="Wild life image"
                    quality={95}                    
                  />
                </div>
                <div className="my-4 text-center">
                  <h3 className="font-semibold text-[#2699FB]">
                    Livestock Wealth Mgt.
                  </h3>
                  <p className="mt-2 text-xs">
                    This is done by partnership basis through sharing of profit/loss
                  </p>
                </div>
              </div>
              <div className="p-2 rounded-md shadow">
                <div className="h-64 w-full rounded-md overflow-hidden">
                  <StaticImage
                    className="object-cover h-full"
                    src="../images/car-garage.png"
                    alt="Car garage image"
                    quality={95}                    
                  />
                </div>
                <div className="my-4 text-center">
                  <h3 className="font-semibold text-[#2699FB]">
                    Investments
                  </h3>
                  <p className="mt-2 text-xs">
                    This is done by partnership basis through sharing of profit/loss
                  </p>
                </div>
              </div>
              <div className="p-2 rounded-md shadow">
                <div className="h-64 w-full rounded-md overflow-hidden">
                  <StaticImage
                    className="object-cover h-full"
                    src="../images/beach-front.png"
                    alt="Beach front image"
                    quality={95}                    
                  />
                </div>
                <div className="my-4 text-center">
                  <h3 className="font-semibold text-[#2699FB]">
                    Home Ownership Schemes
                  </h3>
                  <p className="mt-2 text-xs">
                    This is done by partnership basis through sharing of profit/loss
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-gradient-to-r from-blue-50 to-white">
          <div className="container max-w-5xl mx-auto">
            <div className="flex flex-wrap sm:flex-row items-center justify-center overflow-hidden">
              <div className="w-5/6 sm:w-1/2 p-6 text-xs text-left">
                <h2 className="leading-none font-bold text-3xl text-center">About Us</h2>
                <p className="mt-5">
                  Hayaan SACCO strives to be the one stop shop for sharia compliant financial solutions for the Muslim
                  Community and other ethically conscious individuals. Not only does Hayaan SACCO provide Ethical
                  Financial solutions but also offers highly innovative and cutting-edge solutions in interest free Credit
                  Access, Social and Business Finance, Savings as well as slew of ethical high return investments.
                </p>
                <div className="mt-3">
                  <p>As a sharia compliant SACCO, Hayaan offers interest free loans and other sharia compliant products such as</p>
                  <ul className="list-disc p-5">
                    <li>Interest Free Loans to members. Member access instant loans with 0% interest and No Hidden Charges</li>
                    <li>Business Financing: This is done partnership basis through sharing of profit/loss</li>
                    <li>Sharia Compliant Investments: Professionally run and high return investments in Halal activities ranging from real estate, livestock, horticulture and other industries</li>
                    <li>Livestock Wealth Management: Hayaan Intends to offer innovative livestock wealth management solutions to pastoralist who suffer perennial droughts. The idea is to enable Pastoralists to develop alternative assets over time from their livestock, whilst still practicing their traditional lifestyle</li>
                    <li>Halal Financial Literacy Training in: Savings, Pensions, Investment and general financial management</li>
                  </ul>
                </div>
                <div className="mt-5 text-center">
                  <Link to="/about" className="py-2 px-4 bg-[#F7931E] rounded-full">Read More</Link>
                </div>
              </div>
              <div className="w-full sm:w-1/2 -mb-2">
                <StaticImage
                  className="object-cover w-full"
                  src="../images/wild-life.png"
                  alt="Wild life image"
                  quality={95}                    
                />
              </div>
            </div>
          </div>
        </section>

        <section className="py-10 px-4">
          <div className="container max-w-5xl mx-auto">
            <h2 className="leading-none font-bold text-3xl text-center">Our Core Principles</h2>
            <div className="mt-5 w-full rounded-md shadow-md overflow-hidden text-sm divide-y-2">
              <div className="flex flex-col md:flex-row">
                <div className="p-4 md:w-1/4 bg-[#CFEDFF] font-bold text-center">Transparency</div>
                <div className="p-4 md:w-3/4 bg-gray-100 text-xs">
                  Hayan activities will be open to all members at all times.
                </div>
              </div>
              <div className="flex flex-col md:flex-row">
                <div className="p-4 md:w-1/4 bg-[#CFEDFF] font-bold text-center">Innovation</div>
                <div className="p-4 md:w-3/4 bg-gray-100 text-xs">
                  Hayaan SACCO will strive to avoid falling into the trap of copying too closely 
                  the conventional SACCO and take calculated risk on new financial innovations that 
                  comply with the ethical requirements of the SACCO
                </div>
              </div>
              <div className="flex flex-col md:flex-row">
                <div className="p-4 md:w-1/4 bg-[#CFEDFF] font-bold text-center">True Partnership</div>
                <div className="p-4 md:w-3/4 bg-gray-100 text-xs">
                  When co-investing with members through capital financing, Hayaan SACCO will share profit and 
                  losses with partners and not engage in hedging activities against recipient of financing
                </div>
              </div>
              <div className="flex flex-col md:flex-row">
                <div className="p-4 md:w-1/4 bg-[#CFEDFF] font-bold text-center">Community Service</div>
                <div className="p-4 md:w-3/4 bg-gray-100 text-xs">
                  Hayaan SACCO will engage in activities that create long term solutions for societal 
                  challenges. Towards, this Hayaan pledges 10% of its annual profit for these activities
                </div>
              </div>
              <div className="flex flex-col md:flex-row">
                <div className="p-4 md:w-1/4 bg-[#CFEDFF] font-bold text-center">Ethics & Integrity</div>
                <div className="p-4 md:w-3/4 bg-gray-100 text-xs">
                  Hayaan SACCO will only engage in activities whose ethics is beyond reproach.
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-10 px-4 bg-[#383431]">
          <div className="container max-w-5xl mx-auto">
            <div className="flex flex-col md:flex-row text-center text-white items-center gap-4">
              <div className="w-1/2 font-bold text-4xl">Ni kusaaave tu!</div>
              <div className="w-1/2 font-semibold text-xl">Simply Dial *842*52#</div>
            </div>
          </div>
        </section>

        <section className="py-10 px-4">
          <div className="container max-w-5xl mx-auto">
            <h2 className="leading-none font-bold text-3xl text-center">Testimonials</h2>
            <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center justify-items-center">
              <div className="p-2 rounded-md shadow">
                <div className="flex gap-4 items-center">
                  <div className="h-12 w-12">
                    <StaticImage
                      className="object-cover w-full"
                      src="../images/person-male.png"
                      alt="person male image"
                      quality={95}                    
                    />
                  </div>
                  <div className="text-xs">
                    <p className="font-semibold">Client name</p>
                    <p className="text-[#0297EC]">Title of person</p>
                  </div>
                </div>
                <div className="mt-2 text-xs text-gray-500">
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptas vel debitis, earum nemo 
                    praesentium sunt minima ut ducimus nisi aliquid dicta aliquam maxime veniam dolore est 
                    impedit? Autem, consequuntur porro?
                  </p>
                </div>
              </div>
              <div className="p-2 rounded-md shadow">
                <div className="flex gap-4 items-center">
                  <div className="h-12 w-12">
                    <StaticImage
                      className="object-cover w-full"
                      src="../images/person-male.png"
                      alt="person male image"
                      quality={95}                    
                    />
                  </div>
                  <div className="text-xs">
                    <p className="font-semibold">Client name</p>
                    <p className="text-[#0297EC]">Title of person</p>
                  </div>
                </div>
                <div className="mt-2 text-xs text-gray-500">
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptas vel debitis, earum nemo 
                    praesentium sunt minima ut ducimus nisi aliquid dicta aliquam maxime veniam dolore est 
                    impedit? Autem, consequuntur porro?
                  </p>
                </div>
              </div>
              <div className="p-2 rounded-md shadow">
                <div className="flex gap-4 items-center">
                  <div className="h-12 w-12">
                    <StaticImage
                      className="object-cover w-full"
                      src="../images/person-male.png"
                      alt="person male image"
                      quality={95}                    
                    />
                  </div>
                  <div className="text-xs">
                    <p className="font-semibold">Client name</p>
                    <p className="text-[#0297EC]">Title of person</p>
                  </div>
                </div>
                <div className="mt-2 text-xs text-gray-500">
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptas vel debitis, earum nemo 
                    praesentium sunt minima ut ducimus nisi aliquid dicta aliquam maxime veniam dolore est 
                    impedit? Autem, consequuntur porro?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-10 px-4 bg-[#CFEDFF]">
          <div className="container my-5 max-w-5xl mx-auto text-center">
            <h2 className="font-bold text-2xl">Subscribe to Hayaan Sacco Newsletter</h2>
            <div className="mt-5 flex flex-col sm:flex-row justify-center gap-4 text-sm">
              <input type={'email'} name="email" placeholder="Email Address" className="p-2 rounded-md w-auto sm:w-64" />
              <button className="py-2 px-4 bg-[#0297EC] hover:bg-blue-500 text-white rounded-md">SUBSCRIBE</button>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
}

const query = graphql`
   query Index {
    placeholderImage: file(relativePath: { eq: "landing-hero-bg.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
   }
 `;

export default IndexPage
